// Internal Common Imports
import Svg from "@common/svg"
import Tooltip from "@common/tooltip/tooltip"
import { ReactNode } from "react"
import { VariantProps, cva } from "class-variance-authority"

// Base props
interface LabelBaseProps {
  label?: ReactNode
  children?: ReactNode
  labelDescription?: string
  labelPosition?: "top" | "left" | "right"
  required?: boolean
  labelInfo?: ReactNode
  onLabelActionClick?: () => void
  type?: string
  id?: string
}
// Position Variants
const labelPositionVariants = cva("gap-2 flex", {
  variants: {
    position: {
      top: "flex-col",
      left: "items-center grid grid-cols-6",
      right: "items-center flex-row-reverse justify-end",
    },
  },
  defaultVariants: {
    position: "top",
  },
})

// Font Variants
const labelFontVariants = cva(
  "items-center text-primary flex text-sm space-x-2 select-none w-full",
  {
    variants: {
      font: {
        normal: "font-normal",
        semibold: "font-semibold",
      },
      cursor: {
        default: "cursor-default",
        pointer: "cursor-pointer",
      },
    },
    defaultVariants: {
      font: "semibold",
      cursor: "default",
    },
  }
)

export interface LabelProps
  extends VariantProps<typeof labelPositionVariants>,
    VariantProps<typeof labelFontVariants>,
    LabelBaseProps {}

export default function Label({
  label,
  children,
  labelDescription,
  labelPosition,
  required,
  labelInfo,
  id,
  font,
  cursor,
}: LabelProps) {
  // Handle label action click

  return (
    <div className={labelPositionVariants({ position: labelPosition })}>
      {label && (
        <div className="relative col-span-2">
          <label
            htmlFor={id}
            style={{
              overflowWrap: "anywhere",
            }}
            className={labelFontVariants({ font, cursor })}
          >
            {label}{" "}
            {required && typeof label == "string" && <span className="mx-1">*</span>}
            {labelInfo && <span className="mx-1">{renderLabelTooltip()}</span>}
          </label>
          {renderLabelDescription(labelDescription)}
        </div>
      )}
      <div className={labelPosition === "left" ? "col-span-4" : ""}>{children}</div>
    </div>
  )

  function renderLabelTooltip() {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <Svg name="question-mark-circle" classes="w-4 h-4 text-muted-foreground" />
        </Tooltip.Trigger>
        <Tooltip.Content>{labelInfo}</Tooltip.Content>
      </Tooltip>
    )
  }

  function renderLabelDescription(description?: string) {
    return (
      description && (
        <div className="text-sm font-normal text-muted-foreground">{description}</div>
      )
    )
  }
}
